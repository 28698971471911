import { auth, provider } from "../firebase-config.js";
import { signInWithPopup } from "firebase/auth";
//import "../styles/Auth.css";
import Cookies from "universal-cookie";
import '../styles/googleLoginButton.css';

const cookies = new Cookies();

export const Auth = ({ setIsAuth }) => {
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      cookies.set("auth-token", result.user.refreshToken);
      setIsAuth(true);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <button className="login-with-google-btn" onClick={signInWithGoogle}> Sign In With Google </button>
  );
};
